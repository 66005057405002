import {productsConstants} from '../_constants';
import { alertActions } from './alert.actions';
import { failure, request, success } from '../../_helpers';
import { commonActions } from './common.actions';
import api from 'src/_helpers/api';

export const productsActions = {
    all,
    filter,
    one
};

function all(currentPage = 1) {
    return dispatch => {
        dispatch(request(productsConstants.REQUEST));
        api().get(`api/products?page=${currentPage}`).then(response => {
            const products = response.data.data;
            const meta = response.data.meta;
            dispatch(success(productsConstants.GET, {all: products.rows}));
            dispatch(success(productsConstants.GET_META, {meta: meta}));
            dispatch(success(productsConstants.GET_PRODUCT_COLUMNS, {product_columns: products.product_columns}));
            dispatch(success(productsConstants.GET_PRODUCT_COLUMN_WIDTHS, {product_column_widths: products.product_column_widths}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(productsConstants.FAIL, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            }
        });
    };
}

function one(productSku, reload=false) {
    return dispatch => {
        if (reload) {
            dispatch(commonActions.loading(true));
        }
        dispatch(request(productsConstants.REQUEST));

        api().get(`api/products/${productSku}`).then(response => {
            const product = response.data;
            dispatch(success(productsConstants.GET_ONE, {product}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(productsConstants.FAIL_ONE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message || error.response.data.Message));
                dispatch(commonActions.failure(error.response.data.message || error.response.data.Message));
            }
        });
    };
}

function filter(currentPage = 1, search = '') {

    return dispatch => {
        dispatch(request(productsConstants.REQUEST));

        api().get(`api/products?page=${currentPage}&search=${search}`).then(response => {
            const products = response.data.data;
            const meta = response.data.meta;
            dispatch(success(productsConstants.GET_FILTER, {all: products.rows}));
            dispatch(success(productsConstants.GET_FILTER_META, {meta}));
            dispatch(success(productsConstants.GET_PRODUCT_COLUMNS, {product_columns: products.product_columns}));
            dispatch(success(productsConstants.GET_PRODUCT_COLUMN_WIDTHS, {product_column_widths: products.product_column_widths}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(productsConstants.FAIL, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            }
        });
    };
}

