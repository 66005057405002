import { productsConstants } from '../_constants';

export function products(state = {}, action) {
    switch (action.type) {
        case productsConstants.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case productsConstants.GET:
            return { ...state, all: action.all, loading: false };

        case productsConstants.GET_ONE:
            return { ...state, product: action.product, loading: false };

        case productsConstants.GET_META:
            return { ...state, meta: action.meta, loading: false };

        case productsConstants.GET_PRODUCT_COLUMNS:
            return { ...state, product_columns: action.product_columns, loading: false };

        case productsConstants.GET_PRODUCT_COLUMN_WIDTHS:
            return { ...state, product_column_widths: action.product_column_widths, loading: false };

        case productsConstants.GET_RETAIL_STORES:
            return { ...state, retail_stores: action.retail_stores, loading: false };

        case productsConstants.FAIL:
        case productsConstants.FAIL_ONE:
            return {
                error: action.error,
                status: action.status
            };

        default:
            return state
    }
}