import { authConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: false, user } : {};

export function auth(state = initialState, action) {
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                loggedIn: false,
                data: action.user
            };
        case authConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loggingIn: false,
                data: action.auth
            };
        case authConstants.SHOW_2FA_SETUP:
            return {
                ...state,
                twoFASetup: action.show
            }
        case authConstants.REFRESH:
            return {
                ...state,
                refresh: action.use
            }
        case authConstants.SHOW_OTP_CHALLENGE:
            return {
                ...state,
                showOtpChallenge: action.show
            }
        case authConstants.LOGIN_FAILURE:
            return {};
        case authConstants.LOGOUT:
            return {};
        default:
            return state
    }
}