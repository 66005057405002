import CIcon from '@coreui/icons-react';
import { CSidebarNavItem, CSidebarNav, CLink, CButton, CSpinner, CTooltip, CBadge, CSelect } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { alertActions } from '../store/_actions';
import axios from 'axios';
import config from '../config';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcodeRead, faPaperPlane } from '@fortawesome/pro-duotone-svg-icons';
import copy from 'copy-to-clipboard';
import api from './api';
import { useDispatch } from 'react-redux';
import { ordersActions } from 'src/store/_actions/orders.actions';
import { history } from 'src/_helpers';
import { format} from 'date-fns';
import { zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';

export const objectToFormData = (obj, form, namespace) => {

  var fd = form || new FormData();
  var formKey;

  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {

      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {

        objectToFormData(obj[property], fd, property);

      } else {

        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }

    }
  }

  return fd;

};

export const objExists = (obj) => {
  if (!obj) return false;

  if (Object.keys(obj).length === 0) return false;

  return true;
}

export const slugify = (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase().replace(/\s+/g, '-').replace(p, c => b.charAt(a.indexOf(c))).replace(/&/g, '-and-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-').replace(/^-+/, '').replace(/-+$/, '');
}

export const Tree = (props) => {
  const data = props.data;
  return (
    <CSidebarNav className="c-sidebar-nav h-100 ps">
      {data.map((item, i) => {
        let prevObject = data[i - 1];
        let styleObj = { paddingLeft: (item.depth - 1) * 20 + 20 };
        let className = props.active === item.to ? " c-sidebar-nav-link " : " c-sidebar-nav-link c-active ";
        className += item.depth === 1 && data[i + 1].depth === 2 ? ' is-nav-toggle ' : className;
        className += item.depth === 2 ? ' is-element-to-toggle ' : className;
        return (
          <CSidebarNavItem key={i}>
            <CLink style={styleObj} className={className} to={'/' + item.to}><CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" /> {item.name}</CLink>

          </CSidebarNavItem>
        )
      })}
    </CSidebarNav>
  )
}

export const flatten = (array, depth, parent) => {
  return array.reduce((p, c, i, a) => {
    if (typeof c[1] === 'object' && c[1] !== null) {
      const item = { name: c[0], depth: depth };
      const flatChildren = flatten(Object.entries(c[1]), depth + 1);
      return p.concat([item]).concat(flatChildren);
    } else {
      const item = { name: c[0], depth: depth };
      return p.concat([item]);
    }
  }, [])
}

// Hook
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const pad = (n) => { return n < 10 ? '0' + n : n }

const getSortingSettings = (type) => {

  let sortingSettings = localStorage.getItem(type + 'SortingSettings');


  if (sortingSettings) {
    sortingSettings = JSON.parse(sortingSettings);

    return sortingSettings;
  }

  return {}
}

export const hop = (obj, key) => {
  if (obj.hasOwnProperty(key)) return true;

  return false;
}

export const handleDownload = (e, file, ext) => {
  const fileName = file;

  axios(file, {
    baseURL: config.apiUrlRoot,
    withCredentials: true,
    method: 'GET',
    responseType: 'blob'
  })
    .then(response => {
      const file = new Blob(
        [response.data],
        { type: 'application/' + ext });
      const fileURL = URL.createObjectURL(file);

      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(file, fileName);
      } else {
        const downloadLink = document.createElement("a");
        let extAdd = ext === 'csv' ? '.csv' : '';

        downloadLink.href = window.URL.createObjectURL(file);

        downloadLink.download = fileName.split("/").pop() + extAdd;

        window.open(downloadLink);
        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export const clone = (obj) => Object.assign({}, obj);

export const renameKey = (object, key, newKey) => {

  const clonedObj = clone(object);

  const targetKey = clonedObj[key];



  delete clonedObj[key];

  clonedObj[newKey] = targetKey;

  return clonedObj;

};

export const loadingSpinner = (size = '') => {
  if (!size) {
    size = 'lg'
  }
  return (
    <>
      <div className="gof-spinner d-flex justify-content-center">
        <CSpinner size={size} color="warning" variant="grow" />
      </div>
    </>
  )
}

export const relativeUrlPath = (url) => {
  try {
    const urlObj = new URL(url);
    return urlObj.pathname.replace('/index.php', '');
  } catch {
    return url;
  }
}

export const syntaxHighlight = (json) => {
  if (typeof json != 'string') {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
    var cls = 'number';
    if (/^"/.test(match)) {
      if (/:$/.test(match)) {
        cls = 'key';
      } else {
        cls = 'string';
      }
    } else if (/true|false/.test(match)) {
      cls = 'boolean';
    } else if (/null/.test(match)) {
      cls = 'null';
    }
    return '<span className="' + cls + '">' + match + '</span>';
  });
}

export const getShippingLabelPdfLink = (trackingNumber, gofOrderId, ref = null) => {
  const barCodeIcon = <span style={{ position: 'relative', top: '0px', width: '20px', cursor: 'pointer' }}> <FontAwesomeIcon color='green' icon={faBarcodeRead} /> </span>
  return <CTooltip content={`Download shipping label with tracking number: ${trackingNumber}`}><a ref={ref ? ref : null} target="_blank" onClick={(e) => handleDownload(e, `${config.apiUrl}pdf/${trackingNumber}-${gofOrderId}`, 'pdf')}>{'  '}{barCodeIcon}{'  '}</a></CTooltip>
}

export const toETC = (date) => {
  return moment(date).tz("America/New_York").format("YYYY-MM-DD h:mm a");
}

export const toLocalTimeZone = (date, format = null) => {
  // const defaultFormat = format ? format : 'h:mm a';
  const defaultFormat = format ? format : 'H:mm';

  var m = moment.utc(date, "YYYY-MM-DD " + defaultFormat); // parse input as UTC
  return m.clone().local().format("YYYY-MM-DD " + defaultFormat);
}

export const toUTC = (date, noFormat = false) => {

  // return noFormat ? moment.utc(date, "YYYY-MM-DD h:mm a") : moment.utc(date, "YYYY-MM-DD h:mm a").format("YYYY-MM-DD h:mm a");
  return noFormat ? moment.utc(date, "YYYY-MM-DD H:mm") : moment.utc(date, "YYYY-MM-DD H:mm").format("YYYY-MM-DD H:mm");
}

export const formateDate = (date) => {
  return moment(date).format("YYYY-MM-DD H:mm");
  // return moment(date).format("YYYY-MM-DD h:mm a");
}

export const formatAsUTC = (date) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let dt = new Date(date);
    dt = formatInTimeZone(dt, timezone, 'yyyy-MM-dd HH:mm');
    dt = zonedTimeToUtc(dt, timezone);
    dt = formatInTimeZone(dt, timezone, 'yyyy-MM-dd HH:mm');
    console.log('new utc', dt);
    return dt;
    // return moment.utc(date, "YYYY-MM-DD H:mm").format("YYYY-MM-DD H:mm");
}

export const getLsDateZone = () => {

  let gofSettings = getGoFSettings();
  
  let lsDateZone = 'UTC';

  if (gofSettings) {
    if (gofSettings?.dateZone) {
        lsDateZone = gofSettings.dateZone;
    }
  }

  return [lsDateZone, gofSettings];
}

export const getGoFSettings = (field = null) => {
  const gofSettingsJson = localStorage.getItem('gofSettings');
  let gofSettings = typeof gofSettingsJson != 'undefined' ? JSON.parse(gofSettingsJson) : false;

  if (gofSettings) {
    if (field) {
      if (gofSettings[field]) return gofSettings[field];
      else return false;
    }
  } else {
    gofSettings = false
  }


  return gofSettings;
}

export const updateGoFSettings = (field, value) => {
  let gofSettings = getGoFSettings();

  if (!gofSettings) {
    gofSettings = {}
  }

  gofSettings[field] = value;

  localStorage.setItem('gofSettings', JSON.stringify(gofSettings));

  return gofSettings;
}

export const getQty = (row, type) => {
  // quantity_fulfilled
  // quantity_shipped
  // quantity_cancelled
  // quantity_to_ship
  if (row && Object.keys(row).length === 0 && row.constructor === Object) return;
  let numItems = 0;
  let qtyNum = 0;
  let qtyType = 'quantity_fulfilled';

  switch (type) {
    case 'cancelled':
      qtyType = 'quantity_cancelled';
      break;
    case 'shipped':
      qtyType = 'quantity_shipped'

  }

  if (!row.order_items) {
    return;
  }

  if (qtyType === 'quantity_fulfilled') {
    numItems = 0;
    row.order_items.forEach(f => {
      numItems += 1 * f.quantity_to_ship;
      f.order_item_fulfilments.forEach(fi => {
        qtyNum += fi.quantity_fulfilled;
      });
    });
  } else {
    row.order_items.forEach(f => { qtyNum += f[qtyType]; numItems += 1 * f.quantity_to_ship });
  }

  return <CTooltip content={`${qtyNum} of ${numItems} items are ${type}.`} style={{ color: numItems === qtyNum ? '#007700' : '#333' }}><CLink className="font-weight-bold ml-2 d-inline-block" >{qtyNum + ' / ' + numItems}</CLink></CTooltip>

}

export const StatusEditForm = ({ order, user }) => {
  const [showForm, setShowForm] = useState(false);
  const [changedStatus, setChangedStatus] = useState(false);
  const dispatch = useDispatch();

  const saveStatus = (e) => {
    e.preventDefault();
    if (changedStatus != 0) {
      api().post('api/update-status', { order_id: order.id, new_status: changedStatus }).then(
        (r) => {
          dispatch(alertActions.success(r.data.message));
          dispatch(ordersActions.one(order.order_number));
          setShowForm(false);
        }
      ).catch((r) => {
        dispatch(alertActions.error(r.data));
      });
    }
  }

  if (user?.permissions?.indexOf('can_edit_order_status') > -1) {
    return (
      <>
        <CIcon className="cursor-pointer" name="cil-pencil" onClick={() => setShowForm(!showForm)} />
        {showForm &&
          <span className="form-inline d-inline-block ml-2">
            <CSelect onChange={(e) => setChangedStatus(e.target.value)}>
              <option value="0">Choose new status</option>
              <option value="Hidden">Hidden</option>
              <option value="Processing">Processing</option>
              <option value="Cancelled">Canceled</option>
              <option value="Complete">Complete</option>
            </CSelect>
            {changedStatus && changedStatus != 0 && <CButton onClick={saveStatus} title="Save" className="ml-3" color="success"><CIcon name="cil-check" /></CButton>}
          </span>}
      </>
    )
  } else {
    return '';
  }
}

export const getStatusColumn = (row) => {
  if (row && Object.keys(row).length === 0 && row.constructor === Object) return;


  switch (row.status.toLowerCase()) {
    case 'processing':
      return <><CBadge style={{ background: '#ee9900', color: '#fff' }} className="p-2">{row.status}</CBadge></>
      break;
    case 'partially shipped':
      return <><CBadge style={{ background: '#bb6600', color: '#fff' }} className="p-2">{row.status}</CBadge></>
      break;
    case 'ready to pickup':
      return <><CBadge style={{ background: '#007700', color: '#fff' }} className="p-2">{row.status}</CBadge></>
      break;
    case 'complete':
      return <><CBadge style={{ background: '#77bb77', color: '#fff' }} className="p-2">{row.status}</CBadge></>
      break;
    case 'cancelled':
      return <><CBadge style={{ background: '#bb7777', color: '#fff' }} className="p-2">{row.status}</CBadge></>
    case 'hidden':
      return <><CBadge style={{ background: '#ccc', color: '#333' }} className="p-2">{row.status}</CBadge></>
    case 'hide':
      return <><CBadge style={{ background: '#ccc', color: '#333' }} className="p-2">{row.status}</CBadge></>
      break;
  }
}

export const updateGofUserSettings = (gofSettings, parameter, value) => {
  if (!gofSettings || Object.prototype.toString.call(gofSettings) !== '[object Object]') {
    gofSettings = {};
  }
  gofSettings[parameter] = value;

  setTimeout(() => {
    api().post('/api/users-settings', { name: 'gofSettings', value: gofSettings }).then((response) => {
    }).catch((error) => { console.log(error) });
  }, 100);

  localStorage.setItem('gofSettings', JSON.stringify(gofSettings));
}

export const deleteGofUserSettings = (gofSettings, parameter) => {
  if (!gofSettings || Object.prototype.toString.call(gofSettings) !== '[object Object]') {
    gofSettings = {};
  }

  if (gofSettings[parameter]) {
      console.log('deleting = ', parameter);
      delete gofSettings[parameter];
  }

  setTimeout(() => {
    api().post('/api/users-settings', { name: 'gofSettings', value: gofSettings }).then((response) => {
    }).catch((error) => { console.log(error) });
  }, 100);

  localStorage.setItem('gofSettings', JSON.stringify(gofSettings));
}

export const formatMoney = (number) => {
  return number.toLocaleString('en-CA', { style: 'currency', currency: 'CAD' });
}

export const nestedSort = (prop1, prop2 = null, direction = 'asc') => (e1, e2) => {
  const a = prop2 ? e1[prop1][prop2] : e1[prop1],
    b = prop2 ? e2[prop1][prop2] : e2[prop1],
    sortOrder = direction === "asc" ? 1 : -1
  return (a < b) ? -sortOrder : (a > b) ? sortOrder : 0;
}

export const TagPlus = () => {
  return <div style={{ width: '33px' }}>
    <svg style={{ fill: "#333" }} version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 596 587" width="33" height="33">
      <path id="path2-8" fillRule="evenodd" className="shp0" d="M295.05 45C298.19 45 301.33 45.31 304.42 45.92C307.5 46.54 310.51 47.45 313.42 48.65C316.32 49.86 319.1 51.34 321.72 53.09C324.33 54.84 326.77 56.84 328.99 59.06L540.87 270.94C559.62 289.69 559.62 320.08 540.87 338.82L336.76 542.94C318.01 561.69 287.62 561.69 268.87 542.94L56.99 331.06C54.77 328.84 52.77 326.4 51.02 323.78C49.28 321.17 47.79 318.39 46.59 315.49C45.38 312.58 44.47 309.57 43.85 306.48C43.24 303.4 42.93 300.26 42.93 297.12L42.93 93C42.93 66.49 64.42 45 90.93 45L295.05 45ZM106.93 157C106.93 183.51 128.42 205 154.93 205C181.44 205 202.93 183.51 202.93 157C202.93 130.49 181.44 109 154.93 109C128.42 109 106.93 130.49 106.93 157ZM301.98 196.82C292.1 196.82 284.08 204.84 284.08 214.72L284.08 295.26L203.54 295.26C193.66 295.26 185.64 303.28 185.64 313.16L185.64 331.06C185.64 340.94 193.66 348.96 203.54 348.96L284.08 348.96L284.08 429.5C284.08 439.38 292.1 447.4 301.98 447.4L319.88 447.4C329.76 447.4 337.78 439.38 337.78 429.5L337.78 348.96L418.32 348.96C428.21 348.96 436.22 340.94 436.22 331.06L436.22 313.16C436.22 303.28 428.21 295.26 418.32 295.26L337.78 295.26L337.78 214.72C337.78 204.84 329.76 196.82 319.88 196.82L301.98 196.82Z" />
    </svg></div>
}

export const toLowerCase = value => String(value).toLowerCase();


export const renderShippingLabels = (row) => {
  if (row.shipping_method.shop_shipping_method_description.toLowerCase().includes('pickup') || row.shipping_method.shop_shipping_method_description.toLowerCase().includes('store pickup') || row.shipping_method.shop_shipping_method_description.toLowerCase().includes('in-pickup')) {
    return 'Store pickup';
  }
  return row.order_shipments.map((d, setToasts) => {
    if (d.tracking_number && d.pdf_label) {
      return getShippingLabelPdfLink(d.tracking_number, row.id);
    } else if (d.tracking_number && !d.pdf_label) {
      return <CTooltip content={`The label for ${d.tracking_number} is available in ShipStation only. Click to copy the order number.`}><FontAwesomeIcon className="mr-1" icon={faPaperPlane} onClick={(e) => { copy(row.order_number); setToasts(prev => [...prev, { message: `${row.order_number} copied to clipboard!`, autohide: 3000, fade: "true" }]); }} /></CTooltip>;
    } else if (!d.tracking_number && !d.pdf_label) return;
  });
}

export const getSynapseUrl = (props) => {
  if (props.store) {
    return props.store.mw_url;
  }
}

export const getQtyForStore = (type, item, storeId) => {
  let qty = 0;
  if (item) {
    item.order_item_fulfilments.forEach(f => {
      if (f.ship_from_store_id == storeId) {
        qty += f[type];
      }
    })
  }

  return qty;
}


export const executeTests = (row) => {
  if (row !== 'all') {
    let endpoint = 'execute-api-tests';
    if (row.name.toLowerCase().includes('ui')) {
      endpoint = 'execute-ui-tests';
    }

    api().post(`api/${endpoint}/${row.id}`).then((res) => {
      window.alert(res.data);
    });
    return;
  }
  api().post(`api/execute-tests`).then((res) => {

  });
}

export const displayJsonOrText = (text) => {
  try {
    return JSON.stringify(JSON.parse(text.replaceAll('\\n', '\n')));
  } catch (e) {
    return text;
  }
}

export const arrayRemove = (arr, value) => {

  return arr.filter(function (ele) {
    return ele != value;
  });
}
export const addQuery = (key, value) => {
  let pathname = window.location.pathname;
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  history.push({
    pathname: pathname,
    search: searchParams.toString()
  });
};

export const removeQuery = (key) => {
  let pathname = window.location.pathname;
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);
  history.push({
    pathname: pathname,
    search: searchParams.toString()
  });
}

export const orderItemIsFulfilledFromTheStore = (order, shipment, storeId, orderItem) => {
  if (orderItem.order_item_fulfilments?.filter(of => of.tracking_number === shipment.tracking_number).length === 0) return false;

  let matchedItems = shipment.order_shipment_items?.filter(si=>{
    return si.order_item_id == orderItem.id;
  })
  if(matchedItems && matchedItems.length > 0) {
    return true;
  }

  if (!orderItem.order_item_fulfilments) return false;
  if (orderItem.order_item_fulfilments.filter(ful => ful.ship_from_store_id === storeId && orderItem.id === ful.order_item_id && ful.tracking_number === shipment.tracking_number).length > 0) return true;

  return false
}


export const updateLocalSettingsFromApi = () => {
  let gofSettingsJson = {};

  api().get('/api/users-settings/gofSettings').then((response) => {
    gofSettingsJson = response?.data
    const localStorageSettings = localStorage.getItem('gofSettings');

    if (gofSettingsJson) {
        localStorage.setItem('gofSettings', JSON.stringify(gofSettingsJson));
    }
  }).catch((error) => {
    
  });

}

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
}

export const localToUTCDate = (dateString) => {
  const date = new Date(dateString);
  if (isValidDate(date)) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }

  return new Date();
}

export const setTheStoreId = () => {
  const userId = localStorage.getItem('profile');

  api().get(`api/users/${userId}/edit`).then(r => {

    const user = r.data.data;
    localStorage.setItem('store_id', user.selected_store);

    history.push('/');
    history.go(0);

  }).catch(error => {

  });

}
