import { suppliersConstants } from '../_constants';

export function suppliers(state = {}, action) {
    switch (action.type) {
        case suppliersConstants.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case suppliersConstants.GET:
            return { ...state, all: action.all, loading: false };

        case suppliersConstants.GET_ONE:
            return { ...state, supplier: action.supplier, loading: false };

        case suppliersConstants.GET_META:
            return { ...state, meta: action.meta, loading: false };

        case suppliersConstants.GET_COLUMNS:
            return { ...state, columns: action.columns, loading: false };

        case suppliersConstants.FAIL:
        case suppliersConstants.FAIL_ONE:
            return {
                error: action.error,
                status: action.status
            };

        default:
            return state
    }
}