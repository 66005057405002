import { commonConstants } from '../_constants';
import api from 'src/_helpers/api';

export const commonActions = {
    request,
    success,
    failure,
    loading,
    selectStore,
    selectTimeZone,
    getJobsTypes,
    getQueues,
    clearFinishedJobsFilters,
    setSidebarShow,
    getJobsTypesMap,
    fetchFinishJobs,
    createBookmark,
    renameBookmark,
    newFilterSettings
};

function request(message) {
    return { type: commonConstants.REQUEST, message };
}

function success(message) {
    return { type: commonConstants.SUCCESS, message };
}

function failure(details) { 
    return { type: commonConstants.FAILURE, errors: details };
}

function loading(isLoading) {
    return { type: commonConstants.LOADING, isLoading };
}

function selectStore(store_id) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        api().post(`api/store`, {'selected_store': store_id }).then(response => {
            const store = response.data;
        }).catch(error => {
            if (error.response) {
                if (error.response.data.details) {
                    dispatch(commonActions.failure(error.response.data.details));
                }
            } 
        });
    };
}

function selectTimeZone(timeZone) {
    return { type: commonConstants.SELECT_TIME_ZONE, timeZone };
}


function getJobsTypes(jobsTypes) {
    return { type: commonConstants.GET_FINISHED_JOBS_TYPES, jobsTypes };
}

function getJobsTypesMap(jobsTypesMap) {
    return { type: commonConstants.GET_FINISHED_JOBS_TYPES_MAP, jobsTypesMap };
}

function getQueues(queues) {
    return { type: commonConstants.GET_FINISHED_JOBS_QUEUES, queues };
}

function clearFinishedJobsFilters(clearFilters) {
    return { type: commonConstants.CLEAR_FINISHED_JOBS_FILTERS, clearFilters };
}

function setSidebarShow(val) {
    return { type: commonConstants.SET_SIDEBAR_SHOW, sidebarShow: val };
}

function fetchFinishJobs(fetchToggle) {
    return { type: commonConstants.FETCH_FINISH_JOBS, fetch: fetchToggle };
}

function createBookmark(bookmarkName) {
    return { type: commonConstants.CREATE_BOOKMARK, bookmarkName };
}

function renameBookmark(bookmarkName, newBookmarkName) {
    return { type: commonConstants.RENAME_BOOKMARK, renameBookmark: { currentBookmarkName: bookmarkName, newBookmarkName: newBookmarkName } };
}

function newFilterSettings(newfilters) {
    return { type: commonConstants.NEW_FILTER_SETTINGS, filters: newfilters };
}