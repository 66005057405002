export const ordersConstants = {
    REQUEST: 'REQUEST_ORDERS',
    GET: 'GET_ORDERS',
    GET_ONE: 'GET_ONE',
    FAIL_GET: 'FAIL_ONE',
    GET_META: 'GET_META',
    GET_COLUMNS: 'GET_COLUMNS',
    GET_COLUMN_WIDTHS: 'GET_COLUMN_WIDTHS',
    GET_RETAIL_STORES: 'GET_RETAIL_STORES',
    FAIL: 'FAIL_ORDERS'
}; 