export const commonConstants = {
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    LOADING: 'LOADING',
    SELECT_TIME_ZONE: 'SELECT_TIME_ZONE',
    GET_FINISHED_JOBS_TYPES: 'GET_FINISHED_JOBS_TYPES',
    GET_FINISHED_JOBS_TYPES_MAP: 'GET_FINISHED_JOBS_TYPES_MAP',
    GET_FINISHED_JOBS_QUEUES: 'GET_FINISHED_JOBS_QUEUES',
    CLEAR_FINISHED_JOBS_FILTERS: 'CLEAR_FINISHED_JOBS_FILTERS',
    FETCH_FINISH_JOBS: 'FETCH_FINISH_JOBS',
    CREATE_BOOKMARK: 'CREATE_BOOKMARK',
    RENAME_BOOKMARK: 'RENAME_BOOKMARK',
    SET_SIDEBAR_SHOW: 'SET_SIDEBAR_SHOW',
    DATE_FILTER_CURRENT_DATE: 'DATE_FILTER_CURRENT_DATE',
    NEW_FILTER_SETTINGS: 'NEW_FILTER_SETTINGS'
};