import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./store/_reducers";
const loggerMiddleware = createLogger();

const store =
  process.env.NODE_ENV === "development"
    ? createStore(
        rootReducer,
        applyMiddleware(thunkMiddleware, loggerMiddleware)
      )
    : createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
