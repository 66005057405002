import { ordersConstants } from '../_constants';

export function orders(state = {}, action) {
    switch (action.type) {
        case ordersConstants.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ordersConstants.GET:
            return { ...state, all: action.all, loading: false };

        case ordersConstants.GET_ONE:
            return { ...state, order: action.order, loading: false };

        case ordersConstants.GET_META:
            return { ...state, meta: action.meta, loading: false };

        case ordersConstants.GET_COLUMNS:
            return { ...state, columns: action.columns, loading: false };

        case ordersConstants.GET_COLUMN_WIDTHS:
            return { ...state, column_widths: action.column_widths, loading: false };

        case ordersConstants.GET_RETAIL_STORES:
            return { ...state, retail_stores: action.retail_stores, loading: false };

        case ordersConstants.FAIL:
        case ordersConstants.FAIL_ONE:
            return {
                error: action.error,
                status: action.status
            };

        default:
            return state
    }
}