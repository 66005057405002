import { settingsConstants } from '../_constants';

export function settings(state = {}, action) {
    switch (action.type) {
        case settingsConstants.REQUEST:
            return {
                loading: true,
                settings: []
            };
        case settingsConstants.GET:
            return { ...state, all: action.items.fields, store: action.items.store, loading: false };
        
        case settingsConstants.SAVE:
            return { ...state, message: action.settings, loading: false };

        case settingsConstants.FAIL:
            return {
                error: action.error,
                status: action.status
            };
     
        default:
            return state
    }
}