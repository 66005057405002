import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux'
import { displayActions, userActions } from '../../store/_actions';
import { commonActions } from 'src/store/_actions/common.actions';

export const PrivateRoute = ({ component: Component, ...rest } ) =>  {

    const qs = queryString.parse( rest.location.search );
    let storeId = null;
    const dispatch = useDispatch();

    if (qs.sid) {
        storeId = qs.sid;
        if (storeId) {
            dispatch(commonActions.selectStore(storeId));
            localStorage.setItem('store_id', storeId); 
        }
    } 

    return (
    
        <Route {...rest} render={props =>  {
            return (
            localStorage.getItem('user')
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }
        } />
    )

}
