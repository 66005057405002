import { attributesConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { failure, request, success } from '../../_helpers';
import { commonActions } from './common.actions';
import api from 'src/_helpers/api';

export const attributesActions = {
    all,
    filter,
    one
};

function all(currentPage = 1) {
    return dispatch => {
        dispatch(request(attributesConstants.REQUEST));

        // const search = index.search('').then(d => console.log(d));

        api().get(`api/attributes?page=${currentPage}`).then(response => {
            const attributes = response.data.data;
            const meta = response.data.meta;
            dispatch(success(attributesConstants.GET, {all: attributes.rows}));
            dispatch(success(attributesConstants.GET_META, {meta}));
            dispatch(success(attributesConstants.GET_COLUMNS, {columns: attributes.columns}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(attributesConstants.FAIL, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            }
        });
    };
}

function one(attributeCode, reload=false) {
    return dispatch => {
        if (reload) {
            dispatch(commonActions.loading(true));
        }
        dispatch(request(attributesConstants.REQUEST));

        api().get(`api/attributes/${attributeCode}`).then(response => {
            const attribute = response.data;
            dispatch(success(attributesConstants.GET_ONE, {attribute}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(attributesConstants.FAIL_ONE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message || error.response.data.Message));
                dispatch(commonActions.failure(error.response.data.message || error.response.data.Message));
            }
        });
    };
}

function filter(currentPage = 1, search = '') {

    return dispatch => {
        dispatch(request(attributesConstants.REQUEST));

        api().get(`api/attributes?page=${currentPage}&search=${search}`).then(response => {
            const attributes = response.data.data;
            const meta = response.data.meta;
            dispatch(success(attributesConstants.GET_FILTER, {all: attributes.rows}));
            dispatch(success(attributesConstants.GET_FILTER_META, {meta}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(attributesConstants.FAIL, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            }
        });
    };
}

