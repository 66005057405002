import { attributesConstants } from '../_constants';

export function attributes(state = {}, action) {
    switch (action.type) {
        case attributesConstants.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case attributesConstants.GET:
            return { ...state, all: action.all, loading: false };

        case attributesConstants.GET_ONE:
            return { ...state, attribute: action.attribute, loading: false };

        case attributesConstants.GET_META:
            return { ...state, meta: action.meta, loading: false };

        case attributesConstants.GET_COLUMNS:
            return { ...state, columns: action.columns, loading: false };

        case attributesConstants.FAIL:
        case attributesConstants.FAIL_ONE:
            return {
                error: action.error,
                status: action.status
            };

        default:
            return state
    }
}