export const defaultColumnWidthsProductsGrid = [
    {
        "columnName": "name",
        "width": 600
    },
    {
        "columnName": "gof_status",
        "width": 100
    },
    {
        "columnName": "sku",
        "width": 180
    },
    {
        "columnName": "style_id",
        "width": 110
    },
    {
        "columnName": "variant_id",
        "width": 110
    },
    {
        "columnName": "price",
        "width": 150
    },
    {
        "columnName": "type_id",
        "width": 180
    },
    {
        "columnName": "style_group_type",
        "width": 180
    }
];