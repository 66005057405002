import store from 'src/store';
import { userConstants } from '../_constants';

export function user(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_ALL_REQUEST:
            return {
                loading: true,
                userForHeader: {},
                items: [],
                realTimeNotifications: []
            };
        case userConstants.GET_ALL_SUCCESS:
            return { ...state, items: action.users, loading: false };

        case userConstants.GET_ALL_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };
        case userConstants.GET_ALL_ROLES_REQUEST:
            return {
                ...state,
                loading: true,
                roles_items: []
            };
        case userConstants.GET_ALL_ROLES_SUCCESS:
            return { ...state, roles_items: action.roles, loading: false };

        case userConstants.GET_ALL_ROLES_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };
        case userConstants.GET_REQUEST:
            return {
                ...state,
                loading: true,
                user: {}
            };
        case userConstants.GET_SUCCESS:
            return { ...state, user: action.user, loading: false };

        case userConstants.ALL_STORES_SUCCESS:
            return { ...state, allStores: action.allStores, loading: false };

        case userConstants.GET_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };

        case userConstants.GET_HEADER_REQUEST:
            return {
                ...state,
                loading: true,
                userForHeader: {}
            };
        case userConstants.GET_HEADER_SUCCESS:
            return { ...state, userForHeader: action.user, loading: false };

        case userConstants.GET_HEADER_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };
        case userConstants.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                message: ''
            };
        case userConstants.UPDATE_SUCCESS:
            return { ...state, message: action.user, loading: false };

        case userConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };

        case userConstants.STORE_REQUEST:
            return {
                ...state,
                loading: true,
                message: ''
            };
        case userConstants.STORE_SUCCESS:
            return { ...state, message: action.user, loading: false };

        case userConstants.STORE_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };

        case userConstants.GET_FIELDS:
            return { ...state, fields: action.fields, loading: false };

        case userConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };
        /// Here
        case userConstants.GET_ROLES_REQUEST:
            return {
                ...state,
                loading: true,
                role: {}
            };
        case userConstants.GET_ROLE_SUCCESS:
            return { ...state, role: action.role, loading: false };

        case userConstants.GET_ROLE_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };
        case userConstants.UPDATE_ROLE_REQUEST:
            return {
                ...state,
                loading: true,
                message: ''
            };
        case userConstants.UPDATE_ROLE_SUCCESS:
            return { ...state, message: action.role, loading: false };

        case userConstants.UPDATE_ROLE_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };

        case userConstants.STORE_ROLE_REQUEST:
            return {
                ...state,
                loading: true,
                message: ''
            };
        case userConstants.STORE_ROLE_SUCCESS:
            return { ...state, message: action.role, loading: false };

        case userConstants.STORE_ROLE_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };

        case userConstants.GET_ROLES_FIELDS:
            return { ...state, role_fields: action.role_fields, loading: false };

        case userConstants.UPDATE_ROLE_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };
            
        case userConstants.IS_ADMIN_USER:
            return { ...state, isAdminUser: action.isAdminUser, loading: false };

        case userConstants.SET_NEW_REALTIME_NOTIFICATION:
            return { ...state, realTimeNotifications: action?.notifications, loading: false };

        case userConstants.IS_ADMIN_USER_FAILURE:
            return {
                ...state,
                error: action.error,
                status: action.status
            };
        default:
            return state
    }
}