import { settingsConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { failure, history, hop, request, success } from '../../_helpers';
import axios from 'axios';
import config from 'src/config';
import { authHeaderString } from '../../_helpers/auth-header';
import { commonActions } from './common.actions';
import api from 'src/_helpers/api';


export const settingsActions = {
    all,
    save,
    groups
};

function all(storeIdParam=0) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(settingsConstants.REQUEST));

        let storeId = localStorage.getItem('store_id');
        if (!storeId) {
            storeId = storeIdParam;
        }

        storeIdParam = storeId ? '?store_id='+storeId : '';

        api().get(`api/settings${storeIdParam}`).then(response => {
            sessionStorage.setItem("current_store_id",storeId);
            const settings = response.data;
            dispatch(success(settingsConstants.GET, {"items": settings}));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(settingsConstants.FAIL, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            } 
        });
    };
}

function groups() {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(settingsConstants.REQUEST));

        api().get(`api/settings-groups`).then(response => {
            const settings = response.data;
            dispatch(success(settingsConstants.GET, {"items": settings}));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(settingsConstants.FAIL, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            } 
        });
    };
}

function save(body) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        // dispatch(request(settingsConstants.REQUEST));
        api().put(`api/settings-save`, body).then(response => {
            const settings = response.data;
            dispatch(success(settingsConstants.SAVE, {settings: settings.fields, store: settings.store}));
            dispatch(alertActions.success(settings.message));
            setTimeout(() => {
                dispatch(alertActions.clear());
            }, 5000);
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(settingsConstants.FAIL, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.data.details) {
                    dispatch(commonActions.failure(error.response.data.details));
                }
            } 
            dispatch(commonActions.loading(false)); 
        });
    };
}   