export const productsConstants = {
    REQUEST: 'REQUEST_PRODUCTS',
    GET: 'GET_PRODUCTS',
    GET_ONE: 'GET_ONE',
    FAIL_ONE: 'FAIL_ONE',
    GET_META: 'GET_META',
    GET_PRODUCT_COLUMNS: 'GET_PRODUCT_COLUMNS',
    GET_PRODUCT_COLUMN_WIDTHS: 'GET_PRODUCT_COLUMN_WIDTHS',
    GET_RETAIL_STORES: 'GET_RETAIL_STORES',
    FAIL: 'FAIL_ORDERS'
}; 