import { authConstants } from '../_constants';
import { authService } from '../_services';
import { alertActions } from './alert.actions';
import { failure, history, hop, request, setTheStoreId, success, updateLocalSettingsFromApi } from '../../_helpers';
import axios from 'axios';
import config from 'src/config';
import { authHeaderString } from '../../_helpers/auth-header';
import { auth } from '../_reducers/auth.reducer';
import api from 'src/_helpers/api';



export const authActions = {
    login,
    logout,
};

function login(username, password, reloadLogin = false) {
    axios.defaults.withCredentials = true;
    return dispatch => {
        dispatch(request(authConstants.LOGIN_REQUEST, { username }));
        api().get(`sanctum/csrf-cookie`).then(() => {
            api(dispatch, reloadLogin).post(`api/login`,  {
                email: username,
                password: password
            }).then(response => {

                localStorage.setItem('user', JSON.stringify({username: username}));
                localStorage.setItem('profile', response.data.user_id);

                if (!reloadLogin) {
                    if (response.data.two_factor_setup_needed) {
                        dispatch(success(authConstants.SHOW_2FA_SETUP, {show: true}));
                        api().get('api/user').then((r) => {
                            const user = r.data.data;
    
                            if (user.permissions.indexOf('2fa_required') > -1) {
                                if (user.has_2fa && !user.two_factor_setup_done) {
                                  
                                    dispatch(success(authConstants.SHOW_2FA_SETUP, {show: true}));
                                } else {
                                    dispatch(success(authConstants.SHOW_2FA_SETUP, {show: false}));
                                    dispatch(success(authConstants.SHOW_OTP_CHALLENGE, {show: true}));
                                }
                            }
                        });
                    } else {
                        if (response.data.two_factor) {
                            dispatch(success(authConstants.LOGIN_SUCCESS, response));
                            dispatch(success(authConstants.SHOW_2FA_SETUP, {show: false}));
                            dispatch(success(authConstants.SHOW_OTP_CHALLENGE, {show: true}));
                        } else {

                            dispatch(success(authConstants.LOGIN_SUCCESS, response));
                            dispatch(success(authConstants.SHOW_OTP_CHALLENGE, {show: false}));
                            dispatch(success(authConstants.REFRESH, {use: true}));
                        } 
                    }
                } else {
                    dispatch(success(authConstants.SHOW_2FA_SETUP, {show: false}));
                    dispatch(success(authConstants.SHOW_OTP_CHALLENGE, {show: true}));

                }

               
            }).catch(error => {
                dispatch(failure(authConstants.LOGIN_FAILURE, auth));
                if (error.response) {
                    //dispatch(alertActions.error(error.response.data.error));
                } 
            });

     
        });
       
    };
}

function logout(token=null, reload=true) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();

        api(null, reload).post(`api/logout`).then(res => {
            localStorage.removeItem('user'); 
            dispatch(alertActions.error(res.response.data.message));
            dispatch(success(authConstants.LOGOUT));
        }).catch(error => {
            localStorage.removeItem('user');
        });
        return { type: authConstants.LOGOUT };
    }
}

