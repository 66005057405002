import { combineReducers } from 'redux';
import { auth } from './auth.reducer';
import { user } from './user.reducer';
import { alert } from './alert.reducer';
import { common } from './common.reducer';
import { settings } from './settings.reducer';
import { orders } from './orders.reducer';
import { products } from './products.reducer';
import { attributes } from './attributes.reducer';
import { suppliers } from './suppliers.reducer';

const rootReducer = combineReducers({
  auth,
  user,
  alert,
  common,
  settings,
  orders,
  products,
  attributes,
  suppliers
});

export default rootReducer;