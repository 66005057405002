import { userConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { failure, history, hop, request, success } from '../../_helpers';
import axios from 'axios';
import config from 'src/config';
import { authHeaderString } from '../../_helpers/auth-header';
import { commonActions } from './common.actions';
import api from 'src/_helpers/api';

export const usersActions = {
    all, 
    getOne,
    getUser,
    update,
    store,
    deleteUser,
    fields,
    getRoles,
    getRole,
    updateRole,
    deleteRole,
    roleFields,
    isAdminUser,
    storeRole,
    addRealTimeNotification
};

function all() {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(userConstants.GET_ALL_REQUEST));

        api().get(`api/users`).then(response => {
            const users = response.data.data;

            dispatch(success(userConstants.GET_ALL_SUCCESS, {users}));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(userConstants.GET_ALL_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            } 

            if (error.response.status === 401) {
                window.location.href = '/logout';
            }
        });
    };
}

function getRoles() {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(userConstants.GET_ALL_ROLES_REQUEST));

        api().get(`api/roles`).then(response => {
            const roles = response.data.data;

            dispatch(success(userConstants.GET_ALL_ROLES_SUCCESS, {roles}));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(userConstants.GET_ALL_ROLES_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            } 
        });
    };
}

function getOne(id, constants = {getRequest: userConstants.GET_REQUEST, getSuccess: userConstants.GET_SUCCESS, getStoresSuccess: userConstants.ALL_STORES_SUCCESS, getFailure: userConstants.GET_FAILURE}) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(constants.getRequest));
        api().get(`api/users/${id}/edit`).then(response => {
            const user = response.data.data;
            dispatch(success(constants.getSuccess, {user}));
            dispatch(success(constants.getStoresSuccess, {allStores: user.allStores}));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(constants.getFailure, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            } 
        });
    };
}


function getUser(id) {
    const constants = {getRequest: userConstants.GET_HEADER_REQUEST, getSuccess: userConstants.GET_HEADER_SUCCESS, getFailure: userConstants.GET_HEADER_FAILURE, getStoresSuccess: userConstants.ALL_STORES_SUCCESS}
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(constants.getRequest));
        api().get(`api/users/${id}/edit`).then(response => {
            const user = response.data.data;
            dispatch(success(constants.getSuccess, {user}));
            dispatch(success(constants.getStoresSuccess, {allStores: user.allStores}));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(constants.getFailure, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            } 
        });
    };
}




function getRole(id) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(userConstants.GET_ROLE_REQUEST));
        api().get(`api/roles/${id}/edit`).then(response => {
            const role = response.data.data;
            dispatch(success(userConstants.GET_ROLE_SUCCESS, {role}));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(userConstants.GET_ROLE_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            } 
        });
    };
}



function update(id, body) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(userConstants.UPDATE_REQUEST));
        api().put(`api/users/${id}`, body).then(response => {
            const user = response.data;
            dispatch(success(userConstants.UPDATE_SUCCESS, {user}));
            dispatch(alertActions.success(user.message));
            dispatch(commonActions.loading(false));
            setTimeout(() => {
                dispatch(alertActions.clear());
            }, 5000);
        }).catch(error => {
            if (error.response) {
                dispatch(commonActions.loading(false));
                dispatch(failure(userConstants.UPDATE_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.data.details) {
                    dispatch(commonActions.failure(error.response.data.details));
                }
            } 
        });
    };
}


function updateRole(id, body) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(userConstants.UPDATE_ROLE_REQUEST));
        api().put(`api/roles/${id}`, body).then(response => {
            const role = response.data;
            dispatch(success(userConstants.UPDATE_ROLE_SUCCESS, {role}));
            dispatch(alertActions.success(role.message));
            dispatch(commonActions.loading(false));
            setTimeout(() => {
                dispatch(alertActions.clear());
            }, 2000);
        }).catch(error => {
            if (error.response) {
                dispatch(commonActions.loading(false));
                dispatch(failure(userConstants.UPDATE_ROLE_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.data.details) {
                    dispatch(commonActions.failure(error.response.data.details));
                }
            } 
        });
    };
}

function store(body) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(userConstants.STORE_REQUEST));
        api().post(`api/users/new`, body).then(response => {
            const user = response.data;
            dispatch(success(userConstants.STORE_SUCCESS, {user}));
            dispatch(alertActions.success(user.message));
            dispatch(commonActions.loading(false));
            setTimeout(() => {
                dispatch(alertActions.clear());
                history.push('/users');
                history.go(0);
            }, 5000);
            
        }).catch(error => {
            if (error.response) {
                dispatch(commonActions.loading(false));
                dispatch(failure(userConstants.STORE_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.data.details) {
                    dispatch(commonActions.failure(error.response.data.details));
                }
            } 
        });
    };
}

function storeRole(body) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(userConstants.STORE_ROLE_REQUEST));
        api().post(`api/roles/new`, body).then(response => {
            const role = response.data;
            dispatch(success(userConstants.STORE_ROLE_SUCCESS, {role}));
            dispatch(alertActions.success(role.message));
            dispatch(commonActions.loading(false));
            setTimeout(() => {
                dispatch(alertActions.clear());
                history.push('/roles');
                history.go(0);
            }, 2000);
            
        }).catch(error => {
            if (error.response) {
                dispatch(commonActions.loading(false));
                dispatch(failure(userConstants.STORE_ROLE_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.data.details) {
                    dispatch(commonActions.failure(error.response.data.details));
                }
            } 
        });
    };
}

function deleteUser(id) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(userConstants.STORE_REQUEST));
        api().post(`api/users/${id}/delete`, {}).then(response => {
            const user = response.data;
            dispatch(success(userConstants.STORE_SUCCESS, {user}));
            dispatch(alertActions.success(user.message));
            setTimeout(() => {
                dispatch(alertActions.clear());
                history.push('/users');
                history.go(0);
            }, 2000);
  
        }).catch(error => {
            if (error.response) {
                dispatch(failure(userConstants.STORE_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.data.details) {
                    dispatch(commonActions.failure(error.response.data.details));
                }
            } 
        });
    };
}


function deleteRole(id) {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(userConstants.STORE_REQUEST));
        api().post(`api/role/${id}/delete`, {}).then(response => {
            const role = response.data;
            dispatch(success(userConstants.STORE_SUCCESS, {role}));
            dispatch(alertActions.success(role.message));
            setTimeout(() => {
                dispatch(alertActions.clear());
                history.push('/roles');
                history.go(0);
            }, 2000);
  
        }).catch(error => {
            if (error.response) {
                dispatch(failure(userConstants.STORE_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.data.details) {
                    dispatch(commonActions.failure(error.response.data.details));
                }
            } 
        });
    };
}



function fields() {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();
        dispatch(request(userConstants.GET_ALL_REQUEST));

        api().get(`api/users/fields`).then(response => {
            const fields = response.data.data;

            dispatch(success(userConstants.GET_FIELDS, {fields}));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(userConstants.GET_FIELDS_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            } 
        });
    };
}



function roleFields() {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();

        api().get(`api/roles/fields`).then(response => {
            const role_fields = response.data.data;

            dispatch(success(userConstants.GET_ROLES_FIELDS, {role_fields}));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(userConstants.GET_ROLE_FIELDS_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            } 
        });
    };
}

function isAdminUser() {
    return dispatch => {
      //  axios.defaults.headers.common['Authorization'] = authHeaderString();

        api().get(`api/is-admin-user`).then(response => {
            const is_admin = response.data;

            dispatch(success(userConstants.IS_ADMIN_USER, {isAdminUser: is_admin.adminUser}));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(userConstants.IS_ADMIN_USER_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message ));
            } 
        });
    };
}

function addRealTimeNotification(notifications) {
    return { type: userConstants.SET_NEW_REALTIME_NOTIFICATION, notifications };
}