export const userConstants = {
    GET_ALL_REQUEST: 'GET_ALL_REQUEST',
    ALL_STORES_SUCCESS: 'ALL_STORES_SUCCESS',
    GET_ALL_SUCCESS: 'GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'GET_ALL_FAILURE',

    GET_ALL_ROLES_REQUEST: 'GET_ALL_ROLES_REQUEST',
    GET_ALL_ROLES_SUCCESS: 'GET_ALL_ROLES_SUCCESS',
    GET_ALL_ROLES_FAILURE: 'GET_ALL_ROLES_FAILURE',

    GET_REQUEST: 'GET_REQUEST',
    GET_SUCCESS: 'GET_SUCCESS',
    GET_FAILURE: 'GET_FAILURE',

    GET_HEADER_REQUEST: 'GET_HEADER_REQUEST',
    GET_HEADER_SUCCESS: 'GET_HEADER_SUCCESS',
    GET_HEADER_FAILURE: 'GET_HEADER_FAILURE',

    UPDATE_REQUEST: 'UPDATE_REQUEST',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS',
    UPDATE_FAILURE: 'UPDATE_FAILURE',

    STORE_REQUEST: 'STORE_REQUEST',
    STORE_SUCCESS: 'STORE_SUCCESS',
    STORE_FAILURE: 'STORE_FAILURE',

    GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
    GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
    GET_ROLE_FAILURE: 'GET_ROLE_FAILURE',

    UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
    UPDATE_ROLE_SUCCESS: 'UPDATE_SUCCESS',
    UPDATE_ROLE_FAILURE: 'UPDATE_ROLE_FAILURE',

    STORE_ROLE_REQUEST: 'STORE_ROLE_REQUEST',
    STORE_ROLE_SUCCESS: 'STORE_ROLE_SUCCESS',
    STORE_ROLE_FAILURE: 'STORE_ROLE_FAILURE',

    GET_FIELDS: 'GET_FIELDS',
    GET_FIELDS_FAILURE: 'GET_FIELDS_FAILURE',

    GET_ROLES_FIELDS: 'GET_ROLES_FIELDS',
    GET_ROLES_FIELDS_FAILURE: 'GET_ROLES_FIELDS_FAILURE',

    IS_ADMIN_USER: 'IS_ADMIN_USER',
    IS_ADMIN_USER_FAILURE: 'IS_ADMIN_USER_FAILURE',

    SET_NEW_REALTIME_NOTIFICATION: 'SET_NEW_REALTIME_NOTIFICATION'
};