import { commonConstants } from '../_constants';

export function common(state = {}, action) {
    switch (action.type) {
        case commonConstants.REQUEST:
            return {
                loading: true,
                message: action.message,
                sidebarShow: false
            };
        case commonConstants.SUCCESS:
            return {...state, message: action.message, loading: false };
        case commonConstants.LOADING:
            return { ...state, loading: action.isLoading };
        case commonConstants.SELECT_TIME_ZONE:
            return { ...state, timeZone: action.timeZone };
        case commonConstants.GET_FINISHED_JOBS_TYPES:
            return { ...state, jobsTypes: action.jobsTypes };
        case commonConstants.GET_FINISHED_JOBS_TYPES_MAP:
                return { ...state, jobsTypesMap: action.jobsTypesMap };
        case commonConstants.GET_FINISHED_JOBS_QUEUES:
            return { ...state, queues: action.queues };
        case commonConstants.CLEAR_FINISHED_JOBS_FILTERS:
            return { ...state, clearFilters: action.clearFilters };
        case commonConstants.SET_SIDEBAR_SHOW:
            return { ...state, sidebarShow: action.sidebarShow };
        case commonConstants.FAILURE:
            return {
                ...state,
                ...action
            };
        case commonConstants.FETCH_FINISH_JOBS:
            return { ...state, fetch: action.fetch };
        case commonConstants.CREATE_BOOKMARK:
            return { ...state, bookmarkName: action.bookmarkName };
        case commonConstants.RENAME_BOOKMARK:
            return { ...state, renameBookmark: action.renameBookmark };
        case commonConstants.NEW_FILTER_SETTINGS:
            return { ...state, newFilters: action.filters };
        default:
            return state
    }
}