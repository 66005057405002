import { suppliersConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { failure, request, success } from '../../_helpers';
import { commonActions } from './common.actions';
import api from 'src/_helpers/api';

export const suppliersActions = {
    all,
    filter,
    one
};

function all(currentPage = 1) {
    return dispatch => {
        dispatch(request(suppliersConstants.REQUEST));

        // const search = index.search('').then(d => console.log(d));

        api().get(`api/suppliers?page=${currentPage}`).then(response => {
            const suppliers = response.data.data;
            const meta = response.data.meta;
            dispatch(success(suppliersConstants.GET, {all: suppliers.rows}));
            dispatch(success(suppliersConstants.GET_META, {meta}));
            dispatch(success(suppliersConstants.GET_COLUMNS, {columns: suppliers.columns}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(suppliersConstants.FAIL, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            }
        });
    };
}

function one(supplierCode, reload=false) {
    return dispatch => {
        if (reload) {
            dispatch(commonActions.loading(true));
        }
        dispatch(request(suppliersConstants.REQUEST));

        api().get(`api/suppliers/${supplierCode}`).then(response => {
            const supplier = response.data;
            dispatch(success(suppliersConstants.GET_ONE, {supplier}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(suppliersConstants.FAIL_ONE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message || error.response.data.Message));
                dispatch(commonActions.failure(error.response.data.message || error.response.data.Message));
            }
        });
    };
}

function filter(currentPage = 1, search = '') {

    return dispatch => {
        dispatch(request(suppliersConstants.REQUEST));

        api().get(`api/suppliers?page=${currentPage}&search=${search}`).then(response => {
            const suppliers = response.data.data;
            const meta = response.data.meta;
            dispatch(success(suppliersConstants.GET_FILTER, {all: suppliers.rows}));
            dispatch(success(suppliersConstants.GET_FILTER_META, {meta}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(suppliersConstants.FAIL, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            }
        });
    };
}

