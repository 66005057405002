import React from 'react'
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { authActions } from 'src/store/_actions';

export const Logout = () => {
    const dispatch = useDispatch()
    let token = '';
    let auth = localStorage.getItem('user');
    if (auth) {
        auth = JSON.parse(auth);
        if (auth.access_token) {
        token = auth.access_token;
        }
    }
    
    dispatch(authActions.logout(token)); 

    localStorage.removeItem('profile');
    localStorage.removeItem('user');
    localStorage.removeItem('store_id');
    
    return (
       <Redirect to="/login" />
    )
}
