import { ordersConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { failure, request, success } from '../../_helpers';
import { commonActions } from './common.actions';
import api from 'src/_helpers/api';

export const ordersActions = {
    all,
    filter,
    one
};

function all(currentPage = 1) {
    return dispatch => {
        dispatch(request(ordersConstants.REQUEST)); 

        api().get(`api/orders?page=${currentPage}`).then(response => {
            const orders = response.data.data;
            const meta = response.data.meta;
            dispatch(success(ordersConstants.GET, {all: orders.rows}));
            dispatch(success(ordersConstants.GET_META, {meta}));
            dispatch(success(ordersConstants.GET_COLUMNS, {columns: orders.columns}));
            dispatch(success(ordersConstants.GET_COLUMN_WIDTHS, {column_widths: orders.column_widths}));
            dispatch(success(ordersConstants.GET_RETAIL_STORES, {retail_stores: orders.retail_stores}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(ordersConstants.FAIL, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            } 
        });
    };
}


function one(orderNumber, reload=false) {
    return dispatch => {
        if (reload) {
            dispatch(commonActions.loading(true));
        }
        dispatch(request(ordersConstants.REQUEST));
        
        api().get(`api/order/${orderNumber}`).then(response => {
            const order = response.data;
            dispatch(success(ordersConstants.GET_ONE, {order}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(ordersConstants.FAIL_ONE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message || error.response.data.Message));
                dispatch(commonActions.failure(error.response.data.message || error.response.data.Message));
            } 
        });
    };
}


function filter(currentPage = 1, search = '') {

    return dispatch => {
        dispatch(request(ordersConstants.REQUEST));

        api().get(`api/orders?page=${currentPage}&search=${search}`).then(response => {
            const orders = response.data.data;
            const meta = response.data.meta;
            dispatch(success(ordersConstants.GET_FILTER, {all: orders.rows}));
            dispatch(success(ordersConstants.GET_FILTER_META, {meta}));
            dispatch(commonActions.loading(false));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(ordersConstants.FAIL, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            } 
        });
    };
}

