import axios from "axios";
import config from "src/config";
import { authActions } from "src/store/_actions";
import { alertActions } from "src/store/_actions/alert.actions";
import { history } from "./history";

export default function api(dispatch=null,loginRepeat=false, rootUrl=false) {
    const api = axios.create({
        baseURL: rootUrl ? rootUrl : config.apiUrlRoot,
        withCredentials: true
    }); 

    api.interceptors.request.use(req=>{
        let storeId = null;
        if(sessionStorage.getItem('current_store_id')) {
            storeId = sessionStorage.getItem('current_store_id');
        }

       if(storeId && req.url.indexOf('_sid') === -1) {
           if(req.url.indexOf("?") === -1) {
               req.url += "?_sid="+storeId;
           } else {
               req.url = req.url + "&_sid="+storeId;
           }
       }
       return req;
    });
    api.interceptors.response.use(response => response, error => {
        if (error.response) {

            if (error.response.status === 401 ) {
                
                if (loginRepeat === false) {
                    history.push('/login');
                    history.go(0);
                }
    
                return Promise.reject()
            } 
            else if (error.response.status === 422) {
                
                if (dispatch) {
                        if (error.response.data.errors.code) {
                            dispatch(alertActions.error(error.response.data.errors.code[0]));
                        } else {
                            dispatch(alertActions.error(error.response.data.errors.email[0]));
                        }

                        if (loginRepeat) {
                            dispatch(authActions.logout(true));
                            dispatch(authActions.login(loginRepeat.username, loginRepeat.password, true));
                        }
                 
                } else {
                    window.alert(JSON.stringify(error.response.data.errors));
                }
            } else if (error.response.status === 302) {
                dispatch(alertActions.error(error.response.data.errors.code[0]));
            }
        
            return Promise.reject(error)
        }
    })


    return api;
}